import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const bannersAPI = createApi({
  reducerPath: 'bannersAPI',
  tagTypes: ['Banners', 'LoginBanner'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createBanner: build.mutation({
      query: ({ formData }) => ({
        url: URLS.carousels,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Banners'],
    }),
    editBanner: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.carousels}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Banners'],
    }),
    deleteBanner: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.carousels}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Banners'],
    }),
    getBanners: build.query({
      query: () => ({
        url: URLS.carousels,
        method: 'get',
      }),
      providesTags: () => ['Banners'],
    }),
    getLoginBanner: build.query({
      query: () => ({
        url: URLS.loginBanner,
        method: 'get',
      }),
      providesTags: () => ['LoginBanner'],
    }),
    editLoginBanner: build.mutation({
      query: ({ formData }) => ({
        url: URLS.loginBanner,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['LoginBanner'],
    }),
  }),
});

export const {
  useGetBannersQuery,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useEditBannerMutation,
  useGetLoginBannerQuery,
  useEditLoginBannerMutation,
} = bannersAPI;
