import cl from 'classnames';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconLogOut } from '../../../../assets/icons';
import routes, { RoutesLink, RouteType } from '../../../../router/routes';
import s from './Menu.module.scss';
import Avatar from '../../../../assets/images/avatar.webp';
import { useRedux } from '../../../../hooks/reduxHook';
import { logout, selectUser } from '../../../../redux';

type IMenuProps = {
  className?: string;
};

type MenuItemsType = RouteType & {
  subMenu: RouteType[];
};

export const Menu: React.FC<IMenuProps> = ({ className }) => {
  const { pathname } = useLocation();
  const { select, dispatch } = useRedux();
  const user = select(selectUser);

  const nPathname = pathname.split('/').slice(0, 2).join('/');
  const subPathname = pathname.split('/').slice(0, 3).join('/');

  const menuItems = useMemo(() => {
    return routes
      .map((el) => ({ ...el, subMenu: [] }))
      .reduce((acc: MenuItemsType[], r: MenuItemsType) => {
        if (user?.roles?.filter((el: string) => r?.permissions.includes(el)).length) {
          if (r.subItemFor) {
            return acc.map((el: MenuItemsType) => {
              if (el.name === r.subItemFor) {
                return { ...el, subMenu: [...el.subMenu, r] };
              }
              return el;
            });
          }

          if (r.isSidebar) {
            acc.push(r);
          }
        }

        return acc;
      }, []);
  }, [user?.roles]);

  const positionIndicator = useMemo(() => {
    const HEIGHT_LINK = 15;
    const HEIGHT_INDICATOR = 30;
    const SPACE_BETWEEN_LINK = 30;
    const SPACE_WRAPPER_TOP = 20;

    const indexElement = menuItems.findIndex((el) => el.path === `/${nPathname.split('/')[1]}`);
    return (
      indexElement * (HEIGHT_LINK + SPACE_BETWEEN_LINK) -
      (HEIGHT_INDICATOR - HEIGHT_LINK) / 2 +
      SPACE_WRAPPER_TOP
    );
  }, [nPathname, menuItems]);

  const handleLogOut = () => {
    dispatch(logout());
  };
  return (
    <div className={cl([className, s.menuWrap])}>
      <Link to={RoutesLink.DASHBOARD} className={s.linkLogo}>
        <div className={s.text}>
          <span>LiquidAcre </span>
          <span>ADMIN PANEL</span>
        </div>
      </Link>
      <div className={s.wrapList}>
        <nav className={s.list}>
          <div className={s.indicator} style={{ top: positionIndicator }} />
          {menuItems.map((item, idx) => (
            <div className={s.linkWrap} key={idx + item.path}>
              <Link
                to={item.path}
                className={cl([s.link], { [s.isActive]: nPathname === item.path })}
              >
                {item.name}
              </Link>
              {item.subMenu.length && nPathname === item.path ? (
                <div className={s.subMenuItemWrap}>
                  {item.subMenu.map((subR) => (
                    <Link
                      to={subR.path}
                      key={idx + subR.path}
                      className={cl([s.subLing], { [s.isActive]: subPathname === subR.path })}
                    >
                      {subR.name}
                    </Link>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </nav>
      </div>

      <div className={s.infoUserWrap}>
        <div className={s.infoUser}>
          <img src={Avatar} alt="avatar" className={s.avatar} />
          <div className={s.content}>
            <span className={s.name}>{user?.username}</span>
            <span className={s.position}>
              {user?.roles?.map((role: string) => (
                <span key={role}>{role}</span>
              ))}
            </span>
          </div>
        </div>
        <IconLogOut className={s.icon} onClick={handleLogOut} />
      </div>
    </div>
  );
};
