import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import { messagesAll } from './translations';
import AppRouter from './router';
import awsConfig from './config/aws';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

Amplify.configure({ ...awsConfig }, { ssr: true });

const App = () => {
  return (
    <IntlProvider locale="en" defaultLocale="en" messages={messagesAll.en}>
      <ToastContainer className="customToastify" autoClose={5000} />

      <Authenticator.Provider>
        <AppRouter />
      </Authenticator.Provider>
    </IntlProvider>
  );
};

export default App;
