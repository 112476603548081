import { lazy } from 'react';

const DashboardPage = lazy(() => import('../pages/Dashboard'));
const LoginPage = lazy(() => import('../pages/Login'));
const UsersInformationPage = lazy(() => import('../pages/UsersInformation'));
const UserDetailPage = lazy(() => import('../pages/UsersInformation/UserDetail'));
// const ServiceHealthPage = lazy(() => import('../pages/ServiceHealth'));

// Create
const CreatePage = lazy(() => import('../pages/Create'));
const CreateAdminPage = lazy(() => import('../pages/Create/Admin'));
const CreateBannerPage = lazy(() => import('../pages/Create/Banner'));
const CreateCollectionPage = lazy(() => import('../pages/Create/Collection'));
const CreateFranchisePage = lazy(() => import('../pages/Create/Franchise'));
const CreateIpOwnerPage = lazy(() => import('../pages/Create/IpOwner'));
const CreateItemPage = lazy(() => import('../pages/Create/Item'));
const CreateCouponPage = lazy(() => import('../pages/Create/Coupon'));

// Contents
const ContentsPage = lazy(() => import('../pages/Contents'));
const ContentsAdminPage = lazy(() => import('../pages/Contents/Admin'));
const ContentsBannerPage = lazy(() => import('../pages/Contents/Banner'));
const ContentsCollectionPage = lazy(() => import('../pages/Contents/Collection'));
const ContentsFranchisePage = lazy(() => import('../pages/Contents/Franchise'));
const ContentsIpOwnerPage = lazy(() => import('../pages/Contents/IpOwner'));
const ContentsItemPage = lazy(() => import('../pages/Contents/Item'));
const ContentsItemDetailPage = lazy(() => import('../pages/Contents/Item/ItemDetail'));
const ContentsLoginBannerPage = lazy(() => import('../pages/Contents/LoginBanner'));
const ContentsCouponPage = lazy(() => import('../pages/Contents/Coupon'));

export type RouteType = {
  path: string;
  name: string;
  subItemFor?: string;
  component: React.FC;
  exact: boolean;
  isAuth: boolean;
  isSidebar: boolean;
  permissions: string[];
};

export const RoutesLink = {
  LOGIN: '/',
  DASHBOARD: '/',
  USERS_INFORMATION: '/users-information',
  USER_DETAIL: '/users-information/:id',
  SERVICE_HEALTH: '/service-health',

  // Create
  CREATE: '/create',
  CREATE_ADMIN: '/create/admin',
  CREATE_BANNER: '/create/banner',
  CREATE_COLLECTION: '/create/collection',
  CREATE_FRANCHISE: '/create/franchise',
  CREATE_IPOWNER: '/create/ip-owner',
  CREATE_ITEM: '/create/item',
  CREATE_COUPON: '/create/coupon',

  // Contents
  CONTENTS: '/contents',
  CONTENTS_ADMIN: '/contents/admin',
  CONTENTS_BANNER: '/contents/banner',
  CONTENTS_COLLECTION: '/contents/collection',
  CONTENTS_FRANCHISE: '/contents/franchise',
  CONTENTS_IPOWNER: '/contents/ip-owner',
  CONTENTS_ITEM: '/contents/item',
  CONTENTS_ITEM_DETAIL: '/contents/item/:id',
  CONTENTS_LOGIN_BANNER: '/contents/login-banner',
  CONTENTS_COUPON: '/contents/coupon',
};

const routes: RouteType[] = [
  {
    path: RoutesLink.LOGIN,
    name: 'Login',
    component: LoginPage,
    exact: true,
    isAuth: false,
    isSidebar: false,
    permissions: [],
  },
  {
    path: RoutesLink.DASHBOARD,
    name: 'Dashboard',
    component: DashboardPage,
    exact: true,
    isAuth: true,
    isSidebar: true,
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.USERS_INFORMATION,
    name: 'Users Information',
    component: UsersInformationPage,
    exact: true,
    isAuth: true,
    isSidebar: true,
    permissions: ['admin'],
  },
  {
    path: RoutesLink.USER_DETAIL,
    name: 'User Detail',
    component: UserDetailPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin'],
  },
  // {
  //   path: RoutesLink.SERVICE_HEALTH,
  //   name: 'Services',
  //   component: ServiceHealthPage,
  //   exact: true,
  //   isAuth: true,
  //   isSidebar: true,
  //   permissions: ['admin'],
  // },
  {
    path: RoutesLink.CREATE,
    name: 'Create',
    component: CreatePage,
    exact: true,
    isAuth: true,
    isSidebar: true,
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CREATE_ADMIN,
    name: 'Create Admin',
    component: CreateAdminPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CREATE_BANNER,
    name: 'Create Banner',
    component: CreateBannerPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CREATE_COLLECTION,
    name: 'Create Collection',
    component: CreateCollectionPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CREATE_FRANCHISE,
    name: 'Create Franchise',
    component: CreateFranchisePage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CREATE_IPOWNER,
    name: 'Create Ip Owner',
    component: CreateIpOwnerPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CREATE_ITEM,
    name: 'Create Item',
    component: CreateItemPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CREATE_COUPON,
    name: 'Coupon',
    component: CreateCouponPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CONTENTS,
    name: 'Contents',
    component: ContentsPage,
    exact: true,
    isAuth: true,
    isSidebar: true,
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.CONTENTS_IPOWNER,
    name: 'Ip Owners',
    component: ContentsIpOwnerPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin', 'brand_owner'],
  },
  {
    path: RoutesLink.CONTENTS_FRANCHISE,
    name: 'Franchises',
    component: ContentsFranchisePage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.CONTENTS_COLLECTION,
    name: 'Collections',
    component: ContentsCollectionPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.CONTENTS_ITEM,
    name: 'Items',
    component: ContentsItemPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.CONTENTS_ITEM_DETAIL,
    name: 'Items Detail',
    component: ContentsItemDetailPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    permissions: ['admin', 'brand_owner', 'brand_owner_ro'],
  },
  {
    path: RoutesLink.CONTENTS_BANNER,
    name: 'Banners',
    component: ContentsBannerPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CONTENTS_ADMIN,
    name: 'Admins',
    component: ContentsAdminPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CONTENTS_LOGIN_BANNER,
    name: 'Login Banner',
    component: ContentsLoginBannerPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin'],
  },
  {
    path: RoutesLink.CONTENTS_COUPON,
    name: 'Coupon',
    component: ContentsCouponPage,
    exact: true,
    isAuth: true,
    isSidebar: false,
    subItemFor: 'Contents',
    permissions: ['admin'],
  },
];

export default routes;
