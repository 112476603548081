import { priceToNumber } from './price';

const checkTypeValue = (v: any, f: any) => {
  if (v === Object(v) && !Array.isArray(v)) return f(v);
  if (
    typeof v === 'string' &&
    v
      .replace(/\s/g, '')
      .replace('.', '')
      .split('')
      .every((el) => Number.isInteger(+el))
  )
    return priceToNumber(v);

  return v;
};

export const removeEmptyInObject = <T extends Record<string, any>>({
  ...obj
}: T): { [k: string]: any } =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) =>
        typeof value === 'object'
          ? Object.keys(Object(value))?.length
          : value || typeof value === 'number' || typeof value === 'boolean',
      )
      .map(([key, value]) => {
        return [key, checkTypeValue(value, removeEmptyInObject)];
      }),
  );
