import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { logout } from '../redux/auth';
import { baseUrl } from '.';
import { store } from '..';
import { fetchAuthSession } from 'aws-amplify/auth';

export const apiAuth: AxiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 1000 * 20,
  headers: {
    ContentType: 'application/json',
  },
});

apiAuth.interceptors.request.use(async (request: any) => {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens?.accessToken?.toString() || '';
    request.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    console.error('Error getting the token:', error);
  }
  return request;
});

const refreshAuthLogic = async () => {
  try {
    const data = await fetchAuthSession();
    const token = data.tokens?.accessToken?.toString() || '';

    if (!token) {
      store.dispatch(logout() as any);
      return await Promise.reject(new Error('Token is expired'));
    }

    return await Promise.resolve();
  } catch (error) {
    store.dispatch(logout() as any);
    return Promise.reject(error);
  }
};

createAuthRefreshInterceptor(apiAuth, refreshAuthLogic, {
  statusCodes: [401],
});
