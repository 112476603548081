import { createSlice } from '@reduxjs/toolkit';
import { TypeUsers } from '.';

const initUsers: TypeUsers = {
  loading: false,
  error: '',
};

const UsersSlice = createSlice({
  name: 'Users',
  initialState: initUsers,
  reducers: {
    startUsersFetching: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    successUsersFetching: (state) => ({
      ...state,
      loading: false,
      error: '',
    }),
    errorUsersFetching: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const { startUsersFetching, successUsersFetching, errorUsersFetching } = UsersSlice.actions;

export default UsersSlice.reducer;
