import { ReactComponent as LogoIcon } from './logo.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as SortDownIcon } from './sort_down.svg';
import { ReactComponent as MoreIcon } from './more.svg';
import { ReactComponent as LogInIcon } from './log_in.svg';
import { ReactComponent as LogOutIcon } from './log_out.svg';
import { ReactComponent as DownloadIcon } from './download.svg';
import { ReactComponent as FilterIcon } from './filter.svg';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as CrossIcon } from './cross.svg';
import { ReactComponent as GridIcon } from './grid.svg';
import { ReactComponent as ImageIcon } from './image.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as StarIcon } from './star.svg';
import { ReactComponent as UserIcon } from './user.svg';
import { ReactComponent as EyeIcon } from './eye.svg';
import { ReactComponent as CartIcon } from './cart.svg';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as CircleCrossIcon } from './circle_cross.svg';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as ErrorIcon } from './error.svg';
import { ReactComponent as CheckSquareIcon } from './check_square.svg';
import { ReactComponent as PrevMonthIcon } from './prev_month.svg';
import { ReactComponent as NextMonthIcon } from './next_month.svg';
import { ReactComponent as PrevYearIcon } from './prev_year.svg';
import { ReactComponent as NextYearIcon } from './next_year.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from './arrow_right.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import { ReactComponent as BigImgIcon } from './bigImg.svg';
import { ReactComponent as PercentUpIcon } from './percent_up.svg';
import { ReactComponent as NotFoundItems } from './not_found_items.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import { ReactComponent as GiftIcon } from './gift.svg';
import { ReactComponent as InfoCircleIcon } from './info-circle.svg';
import { ReactComponent as ZipIcon } from './zip.svg';
import { ReactComponent as HelpCircleIcon } from './help_circle.svg';
import { ReactComponent as FireIcon } from './fire.svg';
import { ReactComponent as TicketIcon } from './ticket.svg';
import { ReactComponent as CopyIcon } from './copy.svg';

import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
import { ReactComponent as WebsiteIcon } from './website.svg';

export const IconCross = (props: React.SVGProps<SVGSVGElement>) => <CrossIcon {...props} />;
export const IconLogOut = (props: React.SVGProps<SVGSVGElement>) => <LogOutIcon {...props} />;
export const IconLogo = (props: React.SVGProps<SVGSVGElement>) => <LogoIcon {...props} />;
export const IconSearch = (props: React.SVGProps<SVGSVGElement>) => <SearchIcon {...props} />;
export const IconSortDown = (props: React.SVGProps<SVGSVGElement>) => <SortDownIcon {...props} />;
export const IconMore = (props: React.SVGProps<SVGSVGElement>) => <MoreIcon {...props} />;
export const IconLogIn = (props: React.SVGProps<SVGSVGElement>) => <LogInIcon {...props} />;
export const IconDownload = (props: React.SVGProps<SVGSVGElement>) => <DownloadIcon {...props} />;
export const IconFilter = (props: React.SVGProps<SVGSVGElement>) => <FilterIcon {...props} />;
export const IconGoogle = (props: React.SVGProps<SVGSVGElement>) => <GoogleIcon {...props} />;
export const IconGrid = (props: React.SVGProps<SVGSVGElement>) => <GridIcon {...props} />;
export const IconImage = (props: React.SVGProps<SVGSVGElement>) => <ImageIcon {...props} />;
export const IconPlus = (props: React.SVGProps<SVGSVGElement>) => <PlusIcon {...props} />;
export const IconStar = (props: React.SVGProps<SVGSVGElement>) => <StarIcon {...props} />;
export const IconUser = (props: React.SVGProps<SVGSVGElement>) => <UserIcon {...props} />;
export const IconEye = (props: React.SVGProps<SVGSVGElement>) => <EyeIcon {...props} />;
export const IconCart = (props: React.SVGProps<SVGSVGElement>) => <CartIcon {...props} />;
export const IconUpload = (props: React.SVGProps<SVGSVGElement>) => <UploadIcon {...props} />;
export const IconCheck = (props: React.SVGProps<SVGSVGElement>) => <CheckIcon {...props} />;
export const IconError = (props: React.SVGProps<SVGSVGElement>) => <ErrorIcon {...props} />;
export const IconArrowLeft = (props: any) => <ArrowLeftIcon {...props} />;
export const IconArrowRight = (props: any) => <ArrowRightIcon {...props} />;
export const IconPrevMonth = (props: any) => <PrevMonthIcon {...props} />;
export const IconNextMonth = (props: any) => <NextMonthIcon {...props} />;
export const IconPrevYear = (props: any) => <PrevYearIcon {...props} />;
export const IconNextYear = (props: any) => <NextYearIcon {...props} />;
export const IconCalendar = (props: any) => <CalendarIcon {...props} />;
export const IconTrash = (props: any) => <TrashIcon {...props} />;
export const IconEdit = (props: any) => <EditIcon {...props} />;
export const IconGift = (props: any) => <GiftIcon {...props} />;
export const IconInfoCircle = (props: any) => <InfoCircleIcon {...props} />;
export const IconZip = (props: any) => <ZipIcon {...props} />;
export const IconRefresh = (props: any) => <RefreshIcon {...props} />;
export const IconCheckSquare = (props: React.SVGProps<SVGSVGElement>) => (
  <CheckSquareIcon {...props} />
);
export const IconCircleCross = (props: React.SVGProps<SVGSVGElement>) => (
  <CircleCrossIcon {...props} />
);
export const IconWarning = (props: React.SVGProps<SVGSVGElement>) => <WarningIcon {...props} />;
export const IconBigImg = (props: React.SVGProps<SVGSVGElement>) => <BigImgIcon {...props} />;
export const IconPercentUp = (props: React.SVGProps<SVGSVGElement>) => <PercentUpIcon {...props} />;
export const ItemsNotFound = (props: React.SVGProps<SVGSVGElement>) => <NotFoundItems {...props} />;
export const IconHelpCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <HelpCircleIcon {...props} />
);

export const IconFacebook = (props: React.SVGProps<SVGSVGElement>) => <FacebookIcon {...props} />;
export const IconTwitter = (props: React.SVGProps<SVGSVGElement>) => <TwitterIcon {...props} />;
export const IconYoutube = (props: React.SVGProps<SVGSVGElement>) => <YoutubeIcon {...props} />;
export const IconWebsite = (props: React.SVGProps<SVGSVGElement>) => <WebsiteIcon {...props} />;
export const IconFire = (props: React.SVGProps<SVGSVGElement>) => <FireIcon {...props} />;
export const IconTicket = (props: React.SVGProps<SVGSVGElement>) => <TicketIcon {...props} />;
export const IconCopy = (props: React.SVGProps<SVGSVGElement>) => <CopyIcon {...props} />;
