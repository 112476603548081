import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BalanceType,
  ChartPropsType,
  EfficiencyResponseType,
  RoyaltiesResponseType,
  ScalarsResponseType,
  TimePeriodType,
  TimeseriesResponseType,
  UserRegCountResponseType,
  UserRegPesponseType,
} from '.';
import { URLS } from '../../api';
import { ISelectOption } from '../../components/FormItems/CustomSelect/type';
import { axiosBaseQuery } from '../../helpers';

export const chartsAPI = createApi({
  baseQuery: axiosBaseQuery,
  tagTypes: ['Charts'],
  endpoints: (build) => ({
    getScalars: build.query<ScalarsResponseType, ChartPropsType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.scalars}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getRoyalties: build.query<RoyaltiesResponseType, ChartPropsType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.royalties}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getTimeseries: build.query<TimeseriesResponseType, ChartPropsType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.timeseries}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getUserReg: build.query<UserRegPesponseType, TimePeriodType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.usersReg}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getUserRegCount: build.query<UserRegCountResponseType, TimePeriodType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.usersRegCount}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getEfficiency: build.query<EfficiencyResponseType, { selected: ISelectOption }>({
      query: ({ selected }) => ({
        url: `${URLS.efficiency}/${selected.value}`,
        method: 'get',
      }),
    }),
    getWallets: build.query<BalanceType[], unknown>({
      query: () => ({
        url: URLS.balances,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useGetScalarsQuery,
  useGetRoyaltiesQuery,
  useGetTimeseriesQuery,
  useGetUserRegQuery,
  useGetUserRegCountQuery,
  useGetEfficiencyQuery,
  useGetWalletsQuery,
} = chartsAPI;
