import FileDownload from 'js-file-download';
import { apiAuth, URLS } from '../../api';
import { AppDispatch } from '../store';
import { errorUsersFetching, startUsersFetching, successUsersFetching } from './reducers.users';

export const exportUsersAsync =
  ({ search, sortObject, itemsId }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startUsersFetching());
      const searchQuery = search ? `&search=${search}` : '';
      const itemsIdQuery = itemsId
        ? `&itemIdOwner=${itemsId.replaceAll(',', '%25').replace(/(%25)$/, '')}`
        : '';

      const sortObjectQuery =
        sortObject.sortField && sortObject.sortType
          ? `&${sortObject.sortField}=${sortObject.sortType}`
          : '';

      const respons = await apiAuth.get(
        `users?export=True${searchQuery}${sortObjectQuery}${itemsIdQuery}`,
      );

      FileDownload(respons.data, 'users.csv');
      dispatch(successUsersFetching());
    } catch (error: any) {
      dispatch(errorUsersFetching(error.code));
    }
  };

export const exportUserNftsAsync =
  ({ id }: { id: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startUsersFetching());

      const respons = await apiAuth.get(URLS.userNfts.replace('<id>', id));
      FileDownload(respons.data, 'user_nfts.csv');
      dispatch(successUsersFetching());
    } catch (error: any) {
      dispatch(errorUsersFetching(error.code));
    }
  };
