type ReplaceOldArrType<T> = {
  oldArr: T[];
  newArr: T[];
  key: string;
};

export const replaceOldArr = <T>({ oldArr, newArr, key }: ReplaceOldArrType<T>): T[] =>
  newArr.reduce(
    (result: any, item: any) => {
      if (result.some((i: any) => i[key] === item[key])) {
        const indexUpdatedElemnt = result.findIndex((el: any) => el[key] === item[key]);
        return [
          ...result.slice(0, indexUpdatedElemnt),
          item,
          ...result.slice(indexUpdatedElemnt + 1),
        ];
      }
      result.push(item);
      return result;
    },
    [...oldArr],
  );
