import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AdminsProps, AdminsType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const adminsAPI = createApi({
  reducerPath: 'adminsAPI',
  tagTypes: ['Admins', 'Roles'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createAdmin: build.mutation({
      query: ({ value }) => ({
        url: URLS.admins,
        method: 'post',
        data: value,
      }),
      invalidatesTags: () => ['Admins'],
    }),
    editAdmin: build.mutation({
      query: ({ value, id }) => ({
        url: `${URLS.admins}/${id}`,
        method: 'put',
        data: value,
      }),
      invalidatesTags: () => ['Admins'],
    }),
    deleteAdmin: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.admins}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Admins'],
    }),
    getAdmins: build.query<AdminsType, AdminsProps>({
      query: ({ page, perPage }) => ({
        url: `${URLS.admins}?page_number=${page}&page_size=${perPage}`,
        method: 'get',
      }),
      providesTags: () => ['Admins'],
    }),
    getRoles: build.query({
      query: () => ({
        url: URLS.roles,
        method: 'get',
      }),
      providesTags: () => ['Roles'],
    }),
  }),
});

export const {
  useCreateAdminMutation,
  useGetRolesQuery,
  useGetAdminsQuery,
  useEditAdminMutation,
  useDeleteAdminMutation,
} = adminsAPI;
