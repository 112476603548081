import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { removeUser, setError, setUser, startFetching } from '.';
import { api, URLS } from '../../api';
import { AppDispatch } from '../store';
import { User, AuthResponse } from './types.auth';

export const login = (user: User) => (dispatch: AppDispatch) => {
  localStorage.setItem('xnl-user', JSON.stringify(user));
  dispatch(setUser({ ...user, isAuth: true }));
};

export const authAsync = (dataForm: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startFetching());
    const { data }: AuthResponse = await api.post(URLS.auth, { ...dataForm });

    dispatch(login(data.user));
  } catch (error: any) {
    const session = await fetchAuthSession();
    dispatch(setError(error?.response?.data));

    if (session?.tokens?.idToken?.toString()) {
      setTimeout(async () => {
        await signOut();
      }, 3000);
    }
  }
};

export const logout = () => async (dispath: AppDispatch) => {
  try {
    await signOut();

    localStorage.removeItem('xnl-user');
    dispath(removeUser());
  } catch (error) {
    dispath(setError(error));
  }
};
