import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { ServicesHealthType } from './types.serviceHealth';

export const serviceHealthAPI = createApi({
  reducerPath: 'serviceHealthAPI',
  tagTypes: ['ServiceHealth'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    getServiceHealth: build.query<ServicesHealthType, {}>({
      query: () => ({
        url: URLS.serviceHealth,
        method: 'get',
      }),
      providesTags: () => ['ServiceHealth'],
    }),
  }),
});

export const { useGetServiceHealthQuery } = serviceHealthAPI;
