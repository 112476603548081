import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IpOwnersProps, IpOwnersType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const ipOwnerAPI = createApi({
  reducerPath: 'ipOwnerAPI',
  tagTypes: ['IpOwner'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createIpOwner: build.mutation({
      query: ({ formData }) => ({
        url: URLS.brands,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['IpOwner'],
    }),
    editIpOwner: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.brands}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['IpOwner'],
    }),
    deleteIpOwner: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.brands}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['IpOwner'],
    }),
    getIpOwner: build.query<IpOwnersType, IpOwnersProps>({
      query: ({ page, perPage, url }) => ({
        url: !url
          ? `${URLS.brands}?page_number=${page}&page_size=${perPage}`
          : `${URLS.brands}?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['IpOwner'],
    }),
  }),
});

export const {
  useCreateIpOwnerMutation,
  useEditIpOwnerMutation,
  useDeleteIpOwnerMutation,
  useGetIpOwnerQuery,
} = ipOwnerAPI;
