import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FranchisesProps, FranchisesType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const franchisesAPI = createApi({
  reducerPath: 'franchisesAPI',
  tagTypes: ['Franchise'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createFranchise: build.mutation({
      query: ({ formData }) => ({
        url: URLS.stores,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Franchise'],
    }),
    editFranchise: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.stores}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Franchise'],
    }),
    deleteFranchise: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.stores}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Franchise'],
    }),
    getFranchises: build.query<FranchisesType, FranchisesProps>({
      query: ({ page, perPage, url }) => ({
        url: !url
          ? `${URLS.stores}?page_number=${page}&page_size=${perPage}`
          : `${URLS.stores}?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['Franchise'],
    }),
  }),
});

export const {
  useCreateFranchiseMutation,
  useEditFranchiseMutation,
  useDeleteFranchiseMutation,
  useGetFranchisesQuery,
} = franchisesAPI;
