import { createApi } from '@reduxjs/toolkit/query/react';
import { ListResponseType, OtherUserType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { UsersSortFilterProps } from '../../pages/UsersInformation/reduce';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Users'],
  endpoints: (build) => ({
    listUsers: build.query<ListResponseType<OtherUserType>, UsersSortFilterProps>({
      query: ({ currentPage, selectValue, kycStatus, search, sortObject, itemsId }) => {
        const searchQuery = search ? `&search=${search}` : '';
        const kycStatusQuery = kycStatus ? `&kycStatus=${kycStatus.value}` : '';
        const itemsIdQuery = itemsId
          ? `&itemIdOwner=${itemsId.replaceAll(',', '%25').replace(/(%25)$/, '')}`
          : '';

        const sortObjectQuery =
          sortObject.sortField && sortObject.sortType
            ? `&${sortObject.sortField}=${sortObject.sortType}`
            : '';

        return {
          url: `${URLS.users}?page_number=${currentPage}&page_size=${selectValue.value}${searchQuery}${sortObjectQuery}${itemsIdQuery}${kycStatusQuery}`,
          method: 'get',
        };
      },
      providesTags: () => ['Users'],
    }),
    banUser: build.mutation({
      query: ({ data }) => ({
        url: URLS.ban,
        method: 'post',
        data,
      }),
    }),
    editUser: build.mutation({
      query: ({ data }) => ({
        url: URLS.users,
        method: 'put',
        data,
      }),
      invalidatesTags: () => ['Users'],
    }),
  }),
});

export const { useListUsersQuery, useBanUserMutation, useEditUserMutation } = usersAPI;
